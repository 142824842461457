<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link
          class="brand-logo"
          :to="{ name: 'login' }"
        >
          <logo />
          <h2 class="brand-text text-primary ml-1">
            {{ appName }}
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('resetPasswordTitle') }}
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('resetPasswordMessage') }}
        </b-card-text>

        <validation-observer ref="vo">
          <b-form
            class="auth-reset-password-form mt-2"
            @submit.prevent="onSubmitForm"
          >
            <b-form-group
              :label="$t('New Password')"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="password"
                vid="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length ? false : null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                    :disabled="isLoading"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label-for="reset-password-confirm"
              :label="$t('Confirm Password')"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="confirm_password"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="passwordConfirm"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length ? false : null"
                    name="reset-password-confirm"
                    placeholder="············"
                    :disabled="isLoading"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">
                  <template v-if="failedRules.confirmed">{{ $t('validationErrorConfirmedPassword') }}</template>
                  <template v-else>{{ errors[0] }}</template>
                </small>
              </validation-provider>
            </b-form-group>
            <b-button
              block
              type="submit"
              variant="primary"
              :disabled="isLoading"
            >
              {{ $t('Set New Password') }}
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import CONFIRM_RESET_PASSWORD from '@/gql/mutation/user/confirmResetPassword.gql'

export default {
  components: {
    Logo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      appName: $themeConfig.app.appName,
      password: '',
      passwordConfirm: '',
      isLoading: false,
      // validation
      required,
      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    onSubmitForm() {
      this.$refs.vo.validate().then(async isValid => {
        if (!isValid) {
          return
        }

        try {
          this.isLoading = true

          await this.$apollo.mutate({
            mutation: CONFIRM_RESET_PASSWORD,
            variables: {
              confirmationCode: this.$route.query.confirmationCode || 'some_confirmation_code',
              newPassword: this.passwordConfirm,
            },
          })

          await this.$router.push({ name: 'login' })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Success'),
              text: this.$t('notificationSuccessPasswordChanged'),
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Error'),
              text: Array.isArray(error.graphQLErrors[0].message)
                ? error.graphQLErrors[0].message[0]
                : error.graphQLErrors[0].message,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
